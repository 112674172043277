.user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.image-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
            to bottom,
            rgba(64, 64, 64, 1) 0%,
            rgba(64, 64, 64, .5) 10%,
            rgba(64, 64, 64, 0) 20%,
            rgba(64, 64, 64, 0) 80%,
            rgba(64, 64, 64, .5) 90%,
            rgba(64, 66, 64, 1) 100%
    );
    z-index: 10;
}

.user:hover .user-location {
    display: inline-block;
}

.user:hover .user-temp {
    display: inline-block;
}

/*.user:hover .image-gallery-left-nav {*/
/*    display: block;*/
/*}*/

/*.user:hover .image-gallery-right-nav {*/
/*    display: block;*/
/*}*/

/*.user:hover .image-gallery-bullets {*/
/*    display: block;*/
/*}*/

.image-text {
    color: white;
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 1);
    z-index: 100;
}

.user-name {
    position: absolute;
    top: 0px;
    left: 5px;
    font-weight: 600;
    font-size: min(1.25vh, 2rem );
}

.user-location {
    position: absolute;
    bottom: 0px;
    left: 5px;
    font-weight: 600;
    font-size: min(1.25vh, 2rem );
    /*display: none;*/
}

.user-temp {
    position: absolute;
    bottom: 0px;
    right: 5px;
    font-weight: 600;
    font-size: min(1.25vh, 2rem );
    /*display: none;*/
}

.user-time {
    position: absolute;
    top: 0px;
    right: 5px;
    font-weight: 600;
    font-size: min(1.25vh, 2rem );
}

.user_current_image {
    height: 15vh;
    width: 15vh;
    max-height: 256px;
    max-width: 256px;
    box-shadow: 25px 25px 50px 0 white inset, -25px -25px 50px 0 white inset;
}

.user_top_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: min(1.25vh, 2rem );
    font-weight: 600;
    width: 15vh;
    max-width: 256px;
}

.user_middle_container {
    height: 15vh;
    width: 15vh;
    max-width: 256px;
    max-height: 256px;
}

.user_bottom_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: min(1.25vh, 2rem );
    width: 15vh;
    max-width: 256px;
}