.main-view {
    padding: 2vh;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1.9vh 3.8vh, rgba(0, 0, 0, 0.22) 0px 1.5vh 1.2vh;
    background-color: white;
}

.main-view_image {
    width: 50vh;
    height: 50vh;
    max-width: 512px;
    max-height: 512px;
}

.hidden {
    display: none;
}

.main-view_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: min(1.25vh, 2rem );
    font-weight: 600;
    width: 50vh;
    max-width: 512px;
}

.main-view_center {
    height: 50vh;
    width: 50vh;
    max-width: 512px;
    max-height: 512px;
}

.main-view_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: min(1.25vh, 2rem );
    width: 50vh;
    max-width: 512px;
    font-weight: 600;
}