.app {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.vertical_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

body {
    overflow-x: hidden;
}