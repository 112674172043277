.team-button {
    width: 17vh;
    height: 6vh;
    padding: 0.75vh 0.75vh;
    /*border-bottom-left-radius: 1.2vh;*/
    /*border-bottom-right-radius: 1.2vh;*/
    border-radius: 1.2vh;
    /*background-color: #eee;*/
    color: #ccc;
    border: 1px solid #ccc;
}

.team-button svg {
    width: 2.5vh;
    height: 2.5vh;
    cursor: pointer;
    fill: currentColor;
}

.team-button:hover {
    background-color: #ccc;
    cursor: pointer;
    color: #fff;
}

.team-button:hover svg {
    fill: currentColor;
}