.team-view {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1vh;
    width: 55vh;
    height: 55vh;
    overflow-y: scroll;
    justify-content: center;
    align-content: start;
}

.team-member {
    background-color: #ccc;
    width: 16vh;
    height: 16vh;
}