.frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5vh 1.5vh;
    /*border-radius: 1.2vh;*/
    /*box-shadow: rgba(0, 0, 0, 0.3) 0px 1.9vh 3.8vh, rgba(0, 0, 0, 0.22) 0px 1.5vh 1.2vh;*/
    background-color: white;
    max-width: 22vh;
    min-height: 100vh;
    min-width: 18vh;
    overflow: scroll;
}

.users-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}