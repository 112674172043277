* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: 'Khula', sans-serif;
}

button {
  border: none;
  background: none;
  box-shadow: none;
  font-size: min(2.25vh, 3rem );
}

button:hover, button:active {
  cursor: pointer;
  text-decoration: underline;
}

.svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.svg-container svg {
  width: 100%;
  height: 100%;
  cursor: pointer;
}